import {defineStore} from 'pinia'
import {NetworkService} from "~/utils/NetworkService";
import type {Fee} from "~/models/fee";
import type {GetPaidPaymentsByUserIdRb} from "~/models/request/getPaidPaymentsByUserIdRb";
import type {PaidPayment} from "~/models/paidPayment";
import type {GetFeesBySourceIdRb} from "~/models/request/getFeesBySourceIdRb";
import type {FeeIdAndQuantity} from "~/models/feeIdAndQuantity";
import type {AddUpdateFeeRb} from "~/models/request/addUpdateFeeRb"
import type {AssignFeeRb} from "~/models/request/assignFeeRb";
import type {updateCashPaymentRb} from "~/models/request/updateCashPaymentRb";


export const feeStore = defineStore({
    id: 'fee-store',
    persist: true,
    state: () => {
        return {
            allFeesOfThisCompany: <Fee[]>[],
            fees: <Fee[]>[],
            paidPayments: <PaidPayment[]>[],
            selectedFees: <Fee[]>[],
            feesForEventRegistration: <Fee[]>[],
            feeIdAndQuantityListForEventRegistration: <FeeIdAndQuantity[]>[],
            feesForCreateEventForm: <Fee[]>[]
        }
    },
    actions: {
        clearFeeStore: function () {
            this.clearFees();
            this.clearSelectedFees();
            this.clearFeesForCreateEventForm();
        },
        clearEventRegistrationData: function () {
          this.clearFeesForEventRegistration();
          this.clearFeeIdAndQuantityListForEventRegistration();
        },
        clearFees: function () {
            this.fees = [];
        },
        clearSelectedFees: function () {
            this.selectedFees = [];
        },
        clearFeesForEventRegistration: function () {
          this.feesForEventRegistration = [];
        },
        clearFeeIdAndQuantityListForEventRegistration: function () {
            this.feeIdAndQuantityListForEventRegistration = [];
        },
        clearFeesForCreateEventForm: function () {
            this.feesForCreateEventForm =[];
        },

        // Return fees loaded of this source
        feesBySourceId: function (){
            const tmpFees = <Fee[]>[];
            for(const tmpFee of this.fees){
                tmpFees.push(tmpFee)
            }
            return tmpFees;
        },

        // Get fee by fee id
        getFeeByIdFromAllFeesOfTheCompany: function (feeId: number) {
          return this.allFeesOfThisCompany.find((fee)=>fee.id==feeId);
        },

        // Select/Unselect fee
        switchFeeSelection: function (fee:Fee){
            const foundFee = this.selectedFees.find((tmpFee)=>tmpFee.id==fee.id);
            if(foundFee){
                this.selectedFees = this.selectedFees.filter((tmpFee)=>tmpFee.id!=fee.id);
            }else{
                this.selectedFees.push(fee);
            }
        },

        // Return selected Fees
        getSelectedFees: function (){
            const tmpFees = <Fee[]>[];
            for(const tmpFee of this.selectedFees){
                tmpFees.push(tmpFee)
            }
            return tmpFees;
        },

        // Get total amount of selectedFees
        selectedFeesTotalAmount: function (studentsCount:number) {
            let amount:number = 0;
            for(const tmpFee of this.fees){
                amount = amount+tmpFee.feeAmount;
            }
            amount = amount*studentsCount;
            return Number(amount).toFixed(2);
        },

        // Load all fees of a company
        loadAllFeesOfACompany: async function (companyId:number) {
            this.allFeesOfThisCompany = [];
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/getFeesByCompanyId';
            const ns = new NetworkService();
            //Type 2 is for club
            let {Result} = await ns.post$fetch(url,{"companyId": companyId},null, "getFeesByCompanyId" + new Date().getMilliseconds() + "_")
            if((Result.length??-1)>0){
                for(const item of Result){
                    this.allFeesOfThisCompany.push(item as Fee);
                }
            }
        },

        // Load all fees from network by source id
        loadAllFeesBySourceId: async function (sourceId:number, companyId:number, withSet:boolean|null) {
            this.fees = [];
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/getFeesBySourceId';
            const ns = new NetworkService();
            //Type 2 is for club
            let {Result} = await ns.post$fetch(url,{
                "CompanyId": companyId,
                "SourceId": sourceId,
                "Type": 2,
            },null, "getFeesBySourceId" + new Date().getMilliseconds() + "_")
            if(withSet && (Result?.length??-1)>0){
                for(const item of Result){
                    this.fees.push(item as Fee);
                }
            }
            return Result;
        },

        // Load all payments paid by user
        loadAllPaidPaymentsOfAUser: async function (paymentsByUserIdRb:GetPaidPaymentsByUserIdRb) {
            this.paidPayments = [];
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/getFeesByUserId';
            const ns = new NetworkService();
            //Type 2 is for club
            let {Result} = await ns.post$fetch(url, paymentsByUserIdRb,null, "GetPaidFeesByUserIdRb" + new Date().getMilliseconds() + "_")
            if((Result?.length??-1)>0){
                for(const item of Result){
                    this.paidPayments.push(item as PaidPayment);
                }
            }
        },

        //Get all fees by source id from server
        getAllFeesBySourceIdFromServer: async function(feesBySourceIdRb:GetFeesBySourceIdRb){
            try{
                const config = useRuntimeConfig()
                let url = '/api/fee/fees-by-sourceId';
                const ns = new NetworkService();
                let {Result} = await ns.post$fetch(url, feesBySourceIdRb, null, "getAllFeesBySourceIdFromServer" + new Date().getMilliseconds() + "_")
                if(Result){
                    return Result;
                }
            }catch (e){
                console.log(e);
            }
        },

        // Set fees for event registrations
        setFeesForEventRegistration: function (fees:Fee[]) {
            this.feesForEventRegistration = fees;
        },

        // Load fees by sourceId from server and set to local storage
        loadAndSetFeesForEventRegistrationBySourceIdFromServer: async function(feesBySourceIdRb:GetFeesBySourceIdRb){
            const fees = await this.getAllFeesBySourceIdFromServer(feesBySourceIdRb);
            if(fees){
                this.setFeesForEventRegistration((fees??[]));
                return fees;
            }
        },

        // Set selected quantity for a fee user chose
        setQuantityOfFeeForEventRegistration: function (feeId: number, quantity: number) {
            const filteredFeeIdAndQuantityList = this.feeIdAndQuantityListForEventRegistration.filter((item)=>item.feeId!=feeId);
            filteredFeeIdAndQuantityList.push(<FeeIdAndQuantity>{feeId, quantity})
            this.feeIdAndQuantityListForEventRegistration = filteredFeeIdAndQuantityList;
        },

        getSelectedFeesForEventRegistration: function () {
            const tmpFees = <Fee[]>[];
            for(const tmpFeeIdAndQuantity of this.feeIdAndQuantityListForEventRegistration){
                const tmpQuantity = tmpFeeIdAndQuantity.quantity;
                if(tmpQuantity>0){
                    const foundFee = this.feesForEventRegistration.find((fee)=>fee.id==tmpFeeIdAndQuantity.feeId);
                    if(foundFee){
                        tmpFees.push(foundFee);
                    }
                }
            }
            return tmpFees;
        },

        getQuantityByFeeIfForEventRegistration: function (feeId: any) {
            let quantity = 0;
            const foundFeeIdAndQuantity = this.feeIdAndQuantityListForEventRegistration.find((item)=>item.feeId==feeId);
            if(foundFeeIdAndQuantity){
                quantity = foundFeeIdAndQuantity.quantity;
            }
            return quantity;
        },
        //only for create event page
        setFeesForCreateEventForm: function (fee: Fee){
            this.feesForCreateEventForm = this.feesForCreateEventForm.filter((item:any) => item.id != fee.id)
            this.feesForCreateEventForm.push(fee);
        },
        assignFeesForCreateEventForm: function (fee: any){
            this.feesForCreateEventForm = this.feesForCreateEventForm.filter((item:any) => item.id != fee.FeeId)
        },
        removeFeesForCreateEventForm: function (fee: any){
            this.feesForCreateEventForm = this.feesForCreateEventForm.filter((item:any) => item.id != fee.FeeId)
        },
        createUpdateFee: async function(addUpdateFeeRb: AddUpdateFeeRb){
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/addUpdateFee';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, addUpdateFeeRb, null, "addUpdateFeeRb" + new Date().getMilliseconds() + "_")
            return Result;
        },

        assignFee: async function(addUpdateFeeRb: AssignFeeRb){
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/assignFee';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, addUpdateFeeRb, null, "addUpdateFeeRb" + new Date().getMilliseconds() + "_")
            return Result;
        },
        updateCashPayment: async function(updateCashPaymentRb: updateCashPaymentRb){
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/updateCashPayment';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, updateCashPaymentRb, null, "addUpdateFeeRb" + new Date().getMilliseconds() + "_")
            return Result;
        },
                
        addSingleFeeToAllFeesOfCurrentCompany: function (fee:Fee)  {
            this.allFeesOfThisCompany.push(fee)
        },

        getTransactionStatistics: async function(companyId: number, from:string="", to:string="", fees:number[]=[]){
            try{
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/fee/getCompletedTransactionStatistics';
                const ns = new NetworkService();
                let {Result} = await ns.post$fetch(url, { companyId,  fees}, null, "getTransactionStatistics" + new Date().getMilliseconds() + "_")
                // let {Result} = await ns.post$fetch(url, { companyId, from, to, fees}, null, "getTransactionStatistics" + new Date().getMilliseconds() + "_")
                if(Result){
                    return Result;
                }
            }catch (e){
                console.log(e);
            }
        },

        getTransactionsByUserId: async function(userId: number, companyId: number, withPlan: boolean=false){
            try{
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/fee/getTransactionsByUserId';
                const ns = new NetworkService();
                let {Result} = await ns.post$fetch(url, { userId, companyId, withPlan}, null, "getTransactionsByUserId" + new Date().getMilliseconds() + "_")
                if(Result){
                    return Result;
                }
            }catch (e){
                console.log(e);
            }
        },

        proceedToRefund: async function(refundRB: any){
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/proceedToRefund';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, refundRB, null, "refundRB" + new Date().getMilliseconds() + "_")
            return Result;
        },

        getInvoicesByCompanyId: async function(companyId: any){
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/getAllInvoicesByCompanyId';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {companyId}, null, "getAllInvoicesByCompanyId" + new Date().getMilliseconds() + "_")
            return Result;
        },

        getInvoicesByStudentId: async function(studentId: any, companyId: any){
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/getAllInvoicesByStudentId';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {studentId, companyId}, null, "getAllInvoicesByStudentId" + new Date().getMilliseconds() + "_")
            return Result;
        },
    },
    getters: {
        getAllFeesBySourceId: state => state.fees,
        getAllPaidPayments: state => state.paidPayments,
        getAllFeesForEventRegistration: state => state.feesForEventRegistration,
        getFeeIdAndQuantityListForEventRegistration: state => state.feeIdAndQuantityListForEventRegistration,
        getTotalAmountOfSelectedFeesForEventRegistration: state => {
            let total = 0;
            for(const feeIdAndQuantity of state.feeIdAndQuantityListForEventRegistration){
                const foundFee = state.feesForEventRegistration.find((fee)=>fee.id==feeIdAndQuantity.feeId);
                const quantity = feeIdAndQuantity.quantity;
                if(foundFee){
                    total = total+ (foundFee.feeAmount*quantity);
                }
            }
            return total.toFixed(1);
        },
        getQuantityByFeeIfForEventRegistrationRef: state => function (feeId: any) {
            let quantity = 0;
            const foundFeeIdAndQuantity = state.feeIdAndQuantityListForEventRegistration.find((item)=>item.feeId==feeId);
            if(foundFeeIdAndQuantity){
                quantity = foundFeeIdAndQuantity.quantity;
            }
            return quantity;
        },
        getTotalSelectedFeeQuantityForEventRegistration: state => {
            let total = 0;
            for(const feeIdQuantity of state.feeIdAndQuantityListForEventRegistration){
                total = total + (feeIdQuantity.quantity??0);
            }
            return total;
        },
        // only for create event page
        getFeesForCreateEventForm: state => state.feesForCreateEventForm,
        getFeeByIdFromEventRegistrationFees: state => function (feeId:number) {
            return state.feesForEventRegistration.find((fee)=>fee.id==feeId);
        },
        getFeeById: state=> function (feeId:number) {
            return state.allFeesOfThisCompany.find((item) => item.id == feeId)
        },
        getFeeNameById: state=> function (feeId:number) {
            let fee = state.allFeesOfThisCompany.find((item) => item.id == feeId);
            return fee?.title ?? ''
        },
        getFeeAmountById: state=> function (feeId:number) {
            let fee = state.allFeesOfThisCompany.find((item) => item.id == feeId);
            return fee?.feeAmount ?? ''
        },
        getActiveFeesOfCompany: state=> state.allFeesOfThisCompany.filter((item:any) => item.status == 1),
        getAllCompanyFees: state =>  state.allFeesOfThisCompany
    },
})